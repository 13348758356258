<template>
  <div class="AppointmentScreenshot">
    <div class="bj"></div>
    <p class="title">预约详情</p>
    <div class="xx">
      <div class="gsmc">
        <div class="gsmcs">公司名称：</div>
        <div class="gsdz">
          <p>{{datas.gsmc}}</p>
          <p>{{datas.gsdz}}</p>
        </div>
      </div>
      <div class="dh">
        <p>联系电话：</p>
        <p>{{datas.gsdh}}</p>
      </div>
    </div>
    <div class="yycq">
      <p class="yyxqt">预约详情</p>
      <div>
        <span>就诊人：</span>
        <span>{{datas.name}}</span>
      </div>
      <div>
        <span>预约时间：</span>
        <span>{{datas.sj}}</span>
      </div>
      <div>
        <span>主治医师：</span>
        <span>{{datas.ys}}</span>
      </div>
      <div style="margin: 0;">
        <span>预约类型：</span>
        <span>微信预约</span>
      </div>
    </div>
    <div style="display: flex;align-items: center;justify-content: center;padding-top: 30px">
      <div ref="code2"></div>
    </div>
    <div class="bottom">
       请截图保存本页面, 到复查点出示该二维码进行检查。
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode"
export default {
  name: "AppointmentScreenshot",
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    // console.log(this.datas)
    this.init()
  },
  methods: {
    async init() {
      this.showewm = true
      let dlm = this.datas.csid
      // let tjm = dlm.slice(0, 4)
      // console.log(tjm,this.AllData.CustomerFull.Customer.CustomerNO)
      // tjm = tjm + this.AllData.CustomerFull.Customer.CustomerNO

      const qrcodeDiv = this.$refs.code2;

      try {
        // 使用 qrcode.js 生成 QR Code
        const url = dlm; // 设置 QR Code 的内容
        const options = {
          width: 150, // 设置宽度
          height: 150, // 设置高度
          margin: 0,//二维码留白边距
        };

        // 使用 qrcode.js 提供的 toCanvas 或 toDataURL 方法生成 QR Code
        const canvas = await QRCode.toCanvas(url, options);

        // 渲染到 DOM 元素中
        qrcodeDiv.appendChild(canvas);
      } catch (error) {
        console.error('Error generating QR Code:', error);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.AppointmentScreenshot {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  z-index: 99;
  top: 0;
  background: #FFFFFF;
}
.bj {
  background: #BCE2FF;
  height: 91px;
  width: 100vw;
  position: absolute;
  z-index: -9;
}
.title {
  text-align: center;
  font-size: 17px;
  padding-top: 17px;
}
.xx {
  background: #F5F5F5;
  border-radius: 7px;
  width: calc(92% - 36px);
  padding: 18px;
  margin: 0 auto;
  margin-top: 15px;
  .gsmc {
    display: flex;
    align-items: start;
    .gsmcs {width: 6em}
    .gsdz {
      width: 60vw;
      p:nth-child(1) {font-weight: bold;}
      p:nth-child(2) {
        font-size: 12px;
        color: #5B5B5B;
        padding-top: 3px;
      }
    }
  }
  .dh {
    display: flex;
    align-items: center;
    padding-top: 14px;
    p:nth-child(1) {width: 6em}
    p:nth-child(2) {font-weight: bold}
  }
}
.yycq {
  background: #F5F5F5;
  border-radius: 7px;
  width: calc(92% - 36px);
  padding: 18px;
  margin: 10px auto;
  .yyxqt {
    font-weight: bold;
    border-bottom: 1px dashed  #7A7A7A;
    padding-bottom: 12px;
  }
  div {
    margin: 12px 0;
    span{display: inline-block;}
    span:nth-child(1) {width: 6em;}
    span:nth-child(2) {font-weight: bold;}
  }
}
.bottom {
  font-size: 14px;
  width: 24em;
  padding: 10px;
  border: 1px dashed #D2D2D2;
  border-radius: 7px;
  margin: 15px auto;
  text-align: center;
  margin-bottom: 90px;
}
</style>
